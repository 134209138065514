import { useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Title } from 'components/Title';
import PedidoitensListPage from 'pages/pedidoitens/List';
import useApp from 'hooks/useApp';

const MasterDetailPages = (props) => {
	const app = useApp();
	const { masterRecord, scrollIntoView = true } = props;
	const activeTab = 0;
	let IsActive = masterRecord.status == "A";
	function scrollToDetailPage() {
		if (scrollIntoView) {
			const pageElement = document.getElementById('master-detailpage');
			if (pageElement) {
				pageElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	}
	// pass form data from master to detail
	function setDetailPageFormData() {
		const record = masterRecord;
		// set  form data
		const pedidoitensFormData = { pedid: record?.id }
		app.setPageFormData('pedidoitens', pedidoitensFormData);
	}
	// pass form data from master to detail
	useEffect(() => {
		scrollToDetailPage();
		setDetailPageFormData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [masterRecord]);
	if (masterRecord) {
		return (
			<div id="master-detailpage">
				<TabView value={activeTab}>
					<TabPanel header={<Title title={`Itens do Pedido Nº ${masterRecord.id}`} headerClass="p-0" titleClass="text-lg font-bold" iconClass="pi pi-th-large" avatarSize="small" separator={false} />}>
						<div className="reset-grid">
							<PedidoitensListPage 
								isSubPage fieldName="pedid" 
								fieldValue={masterRecord.id} 
								showBreadcrumbs={false} 
								showHeader={IsActive} 
								IsActive={IsActive}
								showFooter={true}
								multiCheckbox={false}
								>
							</PedidoitensListPage>
						</div>
					</TabPanel>
				</TabView>
			</div>
		);
	}
}
export default MasterDetailPages;
