import { useContext, useState, useEffect  } from 'react';
import { AppContext } from 'contexts/AppContext';
import { FormatComponents } from 'helpers/helper.format';
import { Utils } from 'helpers/helper.util';
import { HelpeComponents } from 'helpers/helper.components';
import { HelperDialog } from 'helpers/helper.dialog';
import { Report } from 'helpers/helper.Print';
import { Print } from 'helpers/helper.Print';
import { PrintPreview } from 'helpers/helper.Print';
import { validarCPF, validarCNPJ } from 'helpers/helper.validation';
import useAuth from "hooks/useAuth";

export default function useApp() {
	const userData = useContext(AppContext);
	const auth = useAuth(); 

	const { FmtQtdChip } = FormatComponents(); 
	const { FmtQtd } = FormatComponents(); 
	const { FmtCurrChip } = FormatComponents(); 
	const { FmtCurr } = FormatComponents(); 
	const { FmtStatusChip } = FormatComponents(); 
	const { FmtStatusSimNaoChip } = FormatComponents(); 
	const { FmtDateTimeBR } = FormatComponents(); 
	const { FmtDateBR } = FormatComponents(); 
	const { FmtDateEN } = FormatComponents(); 
	const { ChooseItem } = Utils(); 
	const { PrintButton } = HelpeComponents(); 
	
	// Dialog
	const dialog  = new HelperDialog();
	//const Confirm = helperDialogInstance.Confirm;

	return {
		...userData, 
		auth,
		//userData, 
		FmtQtdChip, 
		FmtQtd,
		FmtCurrChip,
		FmtCurr,
		FmtStatusChip,
		FmtStatusSimNaoChip,
		FmtDateEN,
		FmtDateBR,
		FmtDateTimeBR,
		// Utils
		ChooseItem,
		// Componentes
		PrintButton,
		dialog,
		Print,
		PrintPreview,
		validarCPF,
		validarCNPJ
	};
}