import axios from 'axios';
import useLocalStore from './useLocalStore';

function useApi(requiresAuth = true) {
    const localStore = useLocalStore();
    // If token exists set header
    if (requiresAuth === true) {
        let token = localStore.getToken();
        let tenancy = localStore.getTenancy();
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        }
        if (tenancy) {
            axios.defaults.headers.common["Tenancy"] = tenancy
        }
    }

    return {
        removeHeader() {
            axios.defaults.headers.common = {}
        },
        get(apiPath) {
            return axios.get(apiPath)
        },

        download(apiPath) {
            return axios({
                url: apiPath,
                method: 'GET',
                responseType: 'blob', // important
            })
        },
        post(apiPath, data) {
            return axios.post(apiPath, data);
        },

        put(apiPath, formData) {
            return axios.put(apiPath, formData)
        },

        patch(apiPath, formData) {
            return axios.patch(apiPath, formData)
        },

        delete(apiPath) {
            return axios.delete(apiPath)
        },
        customRequest(data) {
            return axios(data)
        },
        axios() {
            return axios;
        },
    }
}
export default useApi;