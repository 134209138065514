import { useFormikContext } from 'formik';
import { Chip } from 'primereact/chip';
import * as React from 'react';

export const Utils = () => {

  function ChooseItem(valor, parametrosRetorno) {
    for (const parametro of parametrosRetorno) {
      if (parametro.valor === valor) {
        return parametro.titulo; // Retorna o título correspondente
      }
    }
    return 'Desconhecido'; // Retorna 'Desconhecido' se o valor não for encontrado
  }
  // Return all Functions
  return { ChooseItem };

}
  

export default Utils;