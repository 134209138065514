import React, { useState, useEffect, useContext } from 'react';
import { Title } from 'components/Title';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataSource } from 'components/DataSource';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import useApi from 'hooks/useApi';
import useUtils from 'hooks/useUtils';
import useApp from 'hooks/useApp';

export default function KardexResumePage() {
  const app = useApp();
  const api = useApi();
  const utils = useUtils();
  const {empid, setempid} = useApp();

  const [pageReady, setPageReady] = useState(true);
  const [ProdID, setProdID] = useState();
  const [MotTipo, setMotTipo] = useState("T");
  const [DataI, setDataI] = useState("T");
  const [DataF, setDataF] = useState("T");
  const [TitlePage, setTitlePage] = useState("Ficha Kardex");
  const [loading, setloading] = useState(false);
  const [disableFilter, setdisableFilter] = useState(true);
  const [DadosKardexResume, setDadosKardexResume] = useState([]);

  useEffect(() => {
    const hoje = new Date();
    const ano = hoje.getFullYear();
    const mes = hoje.getMonth();

    const primeiroDia = new Date(ano, mes, 1);
    setDataI(primeiroDia);

    const ultimoDia = new Date(ano, mes + 1, 0); // Dia 0 do próximo mês é o último dia do mês atual
    setDataF(ultimoDia);
  }, []);

  const [filtro, setFiltro] = useState({
    produto: '',
    dataInicial: null,
    dataFinal: null,
    tipoMovimentacao: null,
  });

  function bodyDate(data) {
    if (data) {
        return (
            <>{app.FmtDateBR(data.data)}</>
        );
    }
}

  
  const onclickFilter = async (event) => {
    if(DataI > DataF){
      window.alert("A data final não pode ser menor que a data inicial")
      return
    }
    try {
        setloading(true);
        const dataIFormat = app.FmtDateEN(DataI);
        const dataFFormat = app.FmtDateEN(DataF);  
        let formPost = {empid : 1, prodid : ProdID, datainicial : dataIFormat, datafinal : dataFFormat};
        let response = await api.post(`Pedidos/kardexresume`, formPost);
        setDadosKardexResume(response.data)
        setloading(false);
    } catch (error) {
        console.clear();
        console.error(error);
    }
}

  const headGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Data" rowSpan={2} />
        <Column header="Estoque Movimentado do Dia" colSpan={5} alignHeader='center' />
      </Row>
      <Row>
        <Column header="Inicial" rowSpan={1}  alignHeader='center' />
        <Column header="Entradas" colSpan={1}  alignHeader='center' />
        <Column header="Saidas" colSpan={1}  alignHeader='center' />
        <Column header="Cancelamentos" colSpan={1}  alignHeader='center' />
        <Column header="Final" colSpan={1}  alignHeader='center' />
      </Row>
    </ColumnGroup>
  );

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Filtragem</span>

      <div className="container">
        <div className="grid">

          <div className="md: col">
            <DataSource apiPath="components_data/prodid_option_list"  >
              {
                ({ response }) =>
                  <>
                    Produto
                    <Dropdown style={{ width: '100%' }}
                      filter
                      name="prodid"
                      optionLabel="label"
                      optionValue="value"
                      value={ProdID}
                      
                      onChange={(e) => 
                        {
                          setProdID(e.value)
                          setdisableFilter(false)
                        }}
                      options={response}
                      label="Produto" placeholder="Selecione um Produto ..."

                    />
                  </>
              }
            </DataSource>

          </div>

          <div className="md: col">
          <label style={{display:'inline-block',width:'74px'}}>Data Inicial</label> 
            <Calendar style={{ width: '100%' }}
              dateFormat="dd/mm/yy"
              placeholder="Data Inicial"
              value={DataI}
              onChange={(e) => setDataI(e.value)}
            />
          </div>

          <div className="md: col">
           <label style={{display:'inline-block',width:'70px'}}> Data Final</label>
            <Calendar style={{ width: '100%' }}
              dateFormat="dd/mm/yy"
              placeholder="Data Final"
              value={DataF}
              onChange={(e) => setDataF(e.value)}
            />
          </div>


          <div className="md: col">
            <Button label="Filtrar" onClick={onclickFilter} icon="pi pi-refresh" disabled={disableFilter} style={{width: '100%' , marginTop: '15px' }} />
          </div>
        </div>
      </div>
    </div>
  );


  return (
    <>
      <div style={{ marginBottom: '50px' }}>
        <h1>{TitlePage}</h1>
      </div>


      <section className="page-section " >
        <div className="container-fluid">
          <div className="grid ">
            <div className="col comp-grid" >
              {/* <PageBreadcrumbs /> */}
              <div className="page-records">
                <DataTable
                  header={header}
                  headerColumnGroup={headGroup}
                  lazy={true}
                  loading={loading}
                  //selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                  value={DadosKardexResume}
                  dataKey="id"
                  //sortField={sortBy}
                  //sortOrder={sortOrder}
                  //onSort={onSort}
                  className=" p-datatable-sm"
                  stripedRows={true}
                  showGridlines={false}
                  rowHover={true}
                  responsiveLayout="stack"
                //emptyMessage={<EmptyRecordMessage />}
                >
                  {/*PageComponentStart*/}
                  <Column headerStyle={{ width: '20%' }} body={bodyDate} header='Data'></Column>
                  <Column headerStyle={{ width: '20%' }} field="inicial" align='center' header='Inicial'></Column>
                  <Column headerStyle={{ width: '20%' }} field="entradas" align='center' header='Etradas'></Column>
                  <Column headerStyle={{ width: '20%' }} field="saidas" align='center' header='Saidas'></Column>
                  <Column headerStyle={{ width: '20%' }} field="cancels" align='center' header='Cancelamentos'></Column>
                  <Column headerStyle={{ width: '20%' }} field="final" align='center' header='Final'></Column>
                  {/*PageComponentEnd*/}
                </DataTable>
              </div>
              {/* <PageFooter /> */}
            </div>
          </div>
        </div>
      </section>



    </>
  );
}
