import { useEffect } from 'react';
import { CanView } from 'components/Can';
import { TabView, TabPanel } from 'primereact/tabview';
import { Title } from 'components/Title';
import EstoqueListPage from 'pages/estoque/List';
import ProdutogruposListPage from 'pages/produtogrupos/List';
import useApp from 'hooks/useApp';

const MasterDetailPages = (props) => {
	const app = useApp();
	const { masterRecord, scrollIntoView = true } = props;
	const activeTab = 0;
	function scrollToDetailPage() {
		if (scrollIntoView) {
			const pageElement = document.getElementById('master-detailpage');
			if(pageElement){
				pageElement.scrollIntoView({behavior:'smooth', block:'start'});
			}
		}
	}
	// pass form data from master to detail
	function setDetailPageFormData(){
		const record = masterRecord;
		// set  form data
		const estoqueFormData = { prodid:record?.id }
		app.setPageFormData('estoque', estoqueFormData);
		// set  form data
		const produtogruposFormData = { proid:record?.id }
		app.setPageFormData('produtogrupos', produtogruposFormData);
	}
	// pass form data from master to detail
	useEffect(() => {
		scrollToDetailPage();
		setDetailPageFormData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [masterRecord]);
	if(masterRecord){
		return (
<div id="master-detailpage">
    <TabView value={activeTab}>
        <TabPanel header={<Title title="Produto Estoque"  headerClass="p-0" titleClass="text-lg font-bold"  iconClass="pi pi-th-large" avatarSize="small"    separator={false} />}>
            <CanView pagePath="estoque">
                <div className="reset-grid">
                    <EstoqueListPage isSubPage  fieldName="prodid" fieldValue={masterRecord.id} showBreadcrumbs={false} showHeader={false} showFooter={false}>
                    </EstoqueListPage>
                </div>
            </CanView>
        </TabPanel>
        {/* <TabPanel header={<Title title="Produto Produtogrupos"  headerClass="p-0" titleClass="text-lg font-bold"  iconClass="pi pi-th-large" avatarSize="small"    separator={false} />}>
            <CanView pagePath="produtogrupos">
                <div className="reset-grid">
                    <ProdutogruposListPage isSubPage  fieldName="proid" fieldValue={masterRecord.id} showBreadcrumbs={false} showHeader={false} showFooter={true}>
                    </ProdutogruposListPage>
                </div>
            </CanView>
        </TabPanel> */}
    </TabView>
</div>
		);
	}
}
export default MasterDetailPages;
