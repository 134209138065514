import React from 'react';

const optionListReports = [
	{index: 1, label: 'Ficha de EPI', group: 'bimer', name: 'ficha_epi', filter: '' },
	{index: 2, label: 'Pedido', group: 'bimer', name: 'pedido_a4', filter: '' },
	{index: 3, label: 'Ficha de Funcionários', group: 'bimer', name: 'ficha_func', filter: '' },
	{index: 4, label: 'Ficha de Produtos', group: 'bimer', name: 'ficha_prod', filter: '' }
];

export function SelectOptionReport(index){
    for (const item of optionListReports) {
        if (item.index === index) {
            return item; 
        }
    }
    return null; 
}

export { optionListReports };