import React from 'react';

const OptMoneyFormats = [
	{name: 'USD', format: 'en-US', currency: 'USD'},
	{name: 'BR', format: 'pt-BR', currency: 'BRL'},
];

export function SelectMoneyFormat(name){
    for (const item of OptMoneyFormats) {
        if (item.name === name) {
            return item; 
        }
    }
    return null; 
}

export { OptMoneyFormats };