import { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataSource } from 'components/DataSource';
import { Button } from 'primereact/button';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import useApp from 'hooks/useApp';
import useLocalStore from 'hooks/useLocalStore';
import { InputTextarea } from 'primereact/inputtextarea';

import useEditPage from 'hooks/useEditPage';

export const TokenInfo = (data) => {
	const localStore = useLocalStore();
	const app = useApp();
	const {empid, setempid} = useApp();
	const [token, setToken] = useState("");
	const [noCop,setNoCop] = useState(true);
	const [empresaT, setEmpresaT] = useState(null);
	const [texto, setTexto] = useState('Seu token será gerado para acesso à empresa. Cuidado para não perde-lo, será mostrado apenas essa vez!');

	
	const empresaToken = () => {
		if(!empresaT)
			setEmpresaT(data.data)
	}

	const TokenGenerate = () => {
		//Lógica de geração de token apenas para fins de teste
		var tk = "";
		var i = 0
		while(i < 20){
			tk += "" + Math.random()*100
			i++
		}
		
		setToken(tk); 
		setNoCop(false);
		
	}
	const destino = ""
	const sendAPI = (tk) => {
		//Lógica de envio para a API
		
		console.log("Enviando token", tk)
	}
	const copy = (tk) => {
		//Funçao que copia para área de transferência
		navigator.clipboard.writeText(tk).then((tk)=>
			alert("Texto copiado com sucesso")
		).catch(err => console.error("Falha ao copiar o texto: ", err))	
	}
	
	return(
		<div>
			<h2 className="col layout-toggle-menu">
				Novo Token
			</h2>
			<div className="col text my-2">
				{texto}
				{empresaToken()}
				{empresaT && 
				<div className='col my-2'>	
					<div className="flex gap-2 justify-content-around flex-column
					card shadow-none p-3 surface-100"> 
					{/* Informações para confirmação de qual empresa será gerado o token */}
						<div className='mb-4'>
							<div className="col text-400 font-medium mb-2">Empresa:</div>
							<div className="font-bold">{empresaT.razaosocial}</div>
						</div>
						<div>
							<div className="col text-400 font-medium mb-2">CNPJ:</div>
							<div className="font-bold">{empresaT.cnpj}</div>
						</div>
					</div>

				</div>
				}
				
			</div>
			
        	<div className="card text-center flex flex-column">
            	<div >
					<InputTextarea 
					value={token} 
					style={{width: "90%"}}
					rows={5}
					cols={30}
					/>
				</div>
				<div style={{width:"95%"}} className='my-2 flex justify-content-end'>
					<Button 
						className="p-button" 
						disabled = {noCop}
						onClick={() => copy(token)} 
						size="small" 
						icon="pi pi-copy" 
						label="Copiar"/>
				</div>
        	</div>
    

			<div className="text-center my-2 flex gap-4 flex-column">
				<div>				
					<Button 
					className="p-button" 
					onClick={TokenGenerate} 
					size="large" 
					icon="pi pi-key" 
					label="Gerar Token"/>
				</div>
				<div>
					<Button 
					className="p-button" 
					onClick={() => sendAPI(token)} 
					type="submit" 
					icon="pi pi-send" 
					size="large" 
					label="Salvar"/>
				</div>
			</div>

		</div>
    );
}

