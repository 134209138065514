import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Title } from 'components/Title';
import useApi from 'hooks/useApi';
import useApp from 'hooks/useApp';
import { SelectOptionReport } from './helper.Print.Const';

// Imprime (função assíncrona)
async function Print({ paramns, onPdfGenerated }) {
  console.clear();
  let opt = SelectOptionReport(paramns.id);

  const apiUrl = process.env.REACT_APP_REPORT_API_URL;
  const _apiUrl = `${apiUrl}print/${opt.group}/${opt.name}?filter=${paramns.filter}`;
  try {
    const response = await fetch(_apiUrl, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });

    if (response.ok) {
      const blob = await response.blob();
      const pdfBlobUrl = URL.createObjectURL(blob);

      if (onPdfGenerated) {
        onPdfGenerated(pdfBlobUrl);
      }

      return pdfBlobUrl; // Retorna a URL do blob
    } else if (response.status === 400) {
      const errorData = await response.json();
      const errorMessage = errorData?.message || 'Requisição inválida';
      throw new Error(errorMessage);
    } else {
      throw new Error('Ocorreu um erro ao buscar o relatório.');
    }
  } catch (error) {
    console.error("Erro ao gerar PDF:", error);
    // Lida com o erro de forma apropriada (ex: exibe mensagem para o usuário)
    throw error; // Permite que o erro seja propagado para o chamador
  }
}

// Visualiza
function PrintPreview({ paramns }) {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar o carregamento
  const [error, setError] = useState(null);         // Estado para armazenar mensagem de erro

  useEffect(() => {
    const gerarPdf = async () => {
      try {
        const resultadoPrint = 
          await Print({paramns,onPdfGenerated: (generatedUrl) => 
            {setPdfUrl(generatedUrl);}
        });
      } catch (error) {
        setError(error.message); // Define a mensagem de erro no estado
      } finally {
        setIsLoading(false); // Termina o carregamento, independentemente de sucesso ou erro
      }
    };

    gerarPdf();
  }, [paramns]);

  return (
    <main id="PrintPage" className="main-page">
      <h4>Relatório:</h4>
      {isLoading ? (
        <p>Carregando PDF...</p> // Mensagem de carregamento
      ) : error ? (
        <p>Erro ao gerar PDF: {error}</p> // Mensagem de erro
      ) : (
        <div>
          <iframe src={pdfUrl} width="100%" height="800px" />
        </div>
      )}
    </main>
  );
}

export { Print, PrintPreview };
