import React, { useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

// helper.Dialog.js
export class HelperDialog {
  // Confirmacao
  async Confirm(title, info) {
    title = title || 'Confirmar';
    info = info || 'Confirmar a operação?'
    return new Promise((resolve) => {
      confirmDialog({
        message: `${info}. Confirma ?`,
        header: `${title}`,
        icon: 'pi pi-info-circle',
        defaultFocus: 'Cancelar',
        acceptClassName: 'p-button-danger',
        accept: () => resolve(true),
        reject: () => resolve(false),
      });
    });
  }
  // Erro
  async Error(info) {
    let title = "Erro";
    info = info
    return new Promise((resolve) => {
      confirmDialog({
        message: `${info}.`,
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel:'Ok',
        accept: () => resolve(true)
      });
    });
  }
}

// helper.Dialog.js (continuação)
const helperDialogInstance = new HelperDialog(); // Cria uma instância da classe
export default { helperDialogInstance }; // Exporta a instância
