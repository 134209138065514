import * as React from 'react';
import { useFormikContext } from 'formik';
import { Button } from 'primereact/button';
import { icon } from 'primereact/button';

export const HelpeComponents = () => {

  function PrintButton({onClick, label}) {
    label = label || '';
    return (
      <Button 
        icon="pi pi-print" 
        rounded severity="warning" 
        aria-label="Filter"
        onClick = {onClick}
        label={label}
      />
    );
  }
  // Return all Functions
  return { PrintButton };
}
  

export default HelpeComponents;