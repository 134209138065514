import { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataSource } from 'components/DataSource';
import { Button } from 'primereact/button';
import useApp from 'hooks/useApp';
import useLocalStore from 'hooks/useLocalStore';

export const SelecaoEmpresa = () => {
	const localStore = useLocalStore();
	const app = useApp();
	const {empid, setempid} = useApp();
	const [empresaID, setEmpresa] = useState(null);
	const [texto, setTexto] = useState('Selecione a empresa no qual você deseja trabalhar. As informações que estão na tela seráo limpas.')

	useEffect(() => { setEmpresa(empid); }, []); 

	const OnhandleChange = (event) => {
		setEmpresa(event.value);
	};	

	const OnClickSelectEmp = async () => {
		setempid(empresaID);
		// Salva o empid no localstore
		localStore.setTenancy(empresaID);
		app.closeDialogs();
	}

	return(
		<div>
			<p-divider className='card-header' align="left"> 
				<h3>Trocar de Empresa</h3>
			</p-divider>
			<div className="col layout-toggle-menu" style={{}}  >
				<DataSource apiPath="components_data/empid_option_list" >
					{
						({ response }) =>

							<div >
								<span className='text-start'>Selecione aqui a Empresa de Trabalho</span>
								<Dropdown 
									className='my-2 w-full text-center'
									name="empid"  
									optionLabel="label" 
									optionValue="value"
									value={empresaID}
									onChange={OnhandleChange}
									options={response}
									label="Empresa"
									placeholder="Selecione uma Empresa de Trabalho ..."
								/>
								{/* <ErrorMessage name="empid" component="span" className="p-error" /> */}
							</div>
							
					}
				</DataSource>	
			</div>

			<div className="col text-center my-2">
				{texto}
			</div>

			<div className="col text-center my-2">
				<Button 
					className="p-button-text" 
					disabled={empresaID == null}
					onClick={OnClickSelectEmp} 
					size="large" 
					icon="pi pi-check" 
					label="Selecionar Empresa"/>
			</div>

		</div>
    );
}
