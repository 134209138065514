import { useFormikContext } from 'formik';
import { Chip } from 'primereact/chip';
import * as React from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { enUS } from 'date-fns/locale';

class DateUtils {
  static useFormattedDate() {

    const handleDateChange = (formik, field, value) => {
      const formattedDate = new Date(value).toISOString().split('T')[0];
      formik.setFieldValue(field, formattedDate);
    };

    return handleDateChange;
  }
}

// Formata um Chip, retornando com cor específica para valores negativos ou zero
export const FormatComponents = () => {

  // Formata Valor de Valor
  function FmtCurrChip({ value }) {
    const formattedValue = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    const isNegativeOrZero = value <= 0;
    return (
      <Chip
        label={formattedValue}
        sx={{
          backgroundColor: isNegativeOrZero ? 'red' : 'default',
          color: isNegativeOrZero ? 'white' : 'default',
          textAlign: 'right',
        }}
      />
    );

  };

  // Formata Valor Monetario
  function FmtCurr(value) {
    const formattedValue = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    return (  formattedValue  );

  };

  // Formata Quantidade de Exibicao
  function FmtQtdChip({ value }) {
    const formattedValue = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value);
    const isNegativeOrZero = value <= 0;
    return (
      <Chip
        label={formattedValue}
        sx={{
          backgroundColor: isNegativeOrZero ? 'red' : 'default',
          color: isNegativeOrZero ? 'white' : 'default',
          textAlign: 'right',
        }}
      />
    );
  };

  // Formata Quantidade
  function FmtQtd(value) {
    const formattedValue = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value);
    return ( formattedValue );
  };

  // Formata o Satus True ou False
  function FmtStatusChip({ value }) {
    return (
      <Chip label={value === true ? 'Ativo' : 'Inativo'} />
    );
  };

  // Formata o Satus Sim ou Nao
  function FmtStatusSimNaoChip({ value }) {
    return (
      <Chip label={value === true ? 'Sim' : 'Não'} />
    );
  };

  // Formata o Satus Sim ou Nao
  function FmtDateBR(value) {
    if (!value) {
      return '';
    }
    return (
      format(value, "dd/MM/yyyy", { locale: ptBR })
    );
  };

  function FmtDateTimeBR(value) {
    if (!value) {
      return '';
    }
    var infoData = format(value, "dd/MM/yyyy hh:mm", { locale: ptBR });
    return (infoData);
  };


  // Formata o Satus Sim ou Nao
  function FmtDateEN(value) {
    return (
      format(value, "yyyy-MM-dd", { locale: enUS })
    );
  };



  // Return all Functions
  return { 
    FmtQtdChip, FmtQtd, FmtCurrChip, FmtCurr, FmtStatusChip, FmtStatusSimNaoChip,
    FmtDateBR, FmtDateTimeBR, FmtDateEN
  };

}

export default DateUtils;

// export default useFormattedDate;