import { Formik, Form, FieldArray, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const ProdutogruposEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
        arrForm: yup.array().of(
            yup.object().shape({
                grupoid: yup.string().required().label("Grupo"),
		        proid: yup.string().required().label("Produto")
            })
        )
	});
	// form default values
	const formDefaultValues = {
		arrForm: [{
            grupoid: '',
            proid: '',
        }]
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/produtogrupos`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="ProdutogruposEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Editar Produtogrupos"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);}}>
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Grupo *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource apiPath="components_data/grupoid_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  
                                                    name="grupoid"     
                                                    optionLabel="label" 
                                                    optionValue="value" 
                                                    value={formik.values.grupoid} 
                                                    onChange={formik.handleChange} 
                                                    options={response} 
                                                    disabled={true}
                                                    label="Grupo"  
                                                    placeholder="Selecione um valor ..."  
                                                    className={inputClassName(formik?.errors?.grupoid)}   />
                                                    <ErrorMessage name="grupoid" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <FieldArray name="arrForm" render={
                                            arrayHelpers => (
                                                <div className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                                    <table cellPadding="3" className="p-datatable-table w-full">
                                                        <thead className="p-table-head text-left">
                                                            <tr>
                                                                <th>Produto</th>
                                                                <td style={{ width: '30px' }}></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                               // formik.values.arrForm.map((rowForm, index) =>
                                                                    <tr className="input-row">
                                                                        <td>
                                                                            <DataSource apiPath="components_data/proid_option_list"  >
                                                                                {
                                                                                    ({ response }) =>
                                                                                        <>
                                                                                            <Dropdown 
                                                                                            name={`proid`} 
                                                                                            optionLabel="label" 
                                                                                            optionValue="value" 
                                                                                            value={formik.values.proid} 
                                                                                            onChange={
                                                                                                (e)=>{
                                                                                                    formik.handleChange(e)
                                                                                                    // formik.values.arrForm[index].grupoid = formik.values.arrForm[0].grupoid
                                                                                            }
                                                                                            } 
                                                                                            options={response} 
                                                                                            label="Produto" 
                                                                                            placeholder="Selecione um valor ..." 
                                                                                            className={inputClassName('')} />
                                                                                            <ErrorMessage name={`proid`} 
                                                                                            component="span" 
                                                                                            className="p-error" 
                                                                                            
                                                                                            />
                                                                                        </>
                                                                                }
                                                                            </DataSource>
                                                                        </td>
                                                                        <th style={{ width: '80px' }}>
                                                                            {
                                                                                // formik.values.arrForm.length > props.minRowCount &&
                                                                                <>
                                                                                    <Button type="button" 
                                                                                    className="p-button-danger p-button-sm" 
                                                                                    onClick={() => arrayHelpers.remove(index)} 
                                                                                    icon="pi pi-times" />
                                                                                    <Button type="button" 
                                                                                    onClick={() => arrayHelpers.insert(index, { ...formData.arrForm[0] })} 
                                                                                    icon="pi pi-plus" 
                                                                                    className="p-button-success p-button-sm" />
                                                                                </>
                                                                            }
                                                                        </th>
                                                                    </tr>
                                                                    //)
                                                            }
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan="100" className="text-right">
                                                                    {
                                                                        // (formik.values.arrForm.length < props.maxRowCount) &&
                                                                        <Button type="button" 
                                                                        onClick={() => arrayHelpers.push({ ...formData.arrForm[0] })} 
                                                                        icon="pi pi-plus" 
                                                                        className="p-button-success p-button-sm" />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            )}
                                    />
                                    {/* <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Produto *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/proid_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  
                                                    name="proid"     
                                                    optionLabel="label" 
                                                    optionValue="value" 
                                                    value={formik.values.proid} 
                                                    onChange={formik.handleChange} 
                                                    options={response} label="Produto"  
                                                    placeholder="Selecione um valor ..."  
                                                    className={inputClassName(formik?.errors?.proid)}/>
                                                    <ErrorMessage name="proid" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => {
                                        console.log(formik)
                                        handleSubmit(e, formik)
                                    }
                                        }  type="submit" label="Atualizar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
ProdutogruposEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'produtogrupos',
	apiPath: 'produtogrupos/addmulti',
	routeName: 'produtogruposedit',
	submitButtonLabel: "Atualizar",
	formValidationError: "O formulário é inválido",
	formValidationMsg: "Por favor, preencha o formulário",
	msgTitle: "Atualizar registro",
	msgAfterSave: "Registro atualizado com sucesso",
	msgBeforeSave: "Deseja Salvar os Dados Agora?",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default ProdutogruposEditPage;
